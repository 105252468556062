@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Raleway:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Raleway:wght@400;700&display=swap');
.App {
  text-align: center;
  overflow-x: hidden !important;
  font-family: 'Raleway', sans-serif !important;

}

a {
  color: inherit;
  text-decoration: none;
}
